


































































































































































































import Vue from "vue";
import {
  mdiWeb,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiYoutube,
  mdiMapMarker,
  mdiPhoneClassic,
  mdiPhone,
  mdiPlus,
} from "@mdi/js";

import CategoryList from "@/components/CategoryList.vue";
import BlockRenderer from "@/components/BlockRenderer.vue";

import { Company } from "@/store/types";

import { getUrl, OpenSocialLink } from "@/helpers";

interface Data {
  company: Company | null;
  loading: boolean;
  showImage: boolean;
  selectedImage: string;
  mdiWeb: string;
  mdiFacebook: string;
  mdiInstagram: string;
  mdiTwitter: string;
  mdiYoutube: string;
  mdiMapMarker: string;
  mdiPhoneClassic: string;
  mdiPhone: string;
  mdiPlus: string;
}

export default Vue.extend({
  name: "Company",
  components: {
    BlockRenderer,
    CategoryList,
  },
  mounted() {
    this.Load();
  },
  data(): Data {
    return {
      company: null,
      loading: false,
      showImage: false,
      selectedImage: null,
      mdiWeb,
      mdiFacebook,
      mdiInstagram,
      mdiTwitter,
      mdiYoutube,
      mdiMapMarker,
      mdiPhoneClassic,
      mdiPhone,
      mdiPlus,
    };
  },
  computed: {
    first() {
      if (!this.company) {
        return [];
      }
      const roots = this.company.categories;
      return roots.slice(0, roots.length / 2);
    },
    second() {
      if (!this.company) {
        return [];
      }
      const roots = this.company.categories;
      return roots.slice(roots.length / 2, roots.length);
    },
  },
  methods: {
    async Load() {
      const companyId = this.$route.params.id;

      if (this.loading === true) {
        return;
      }

      this.loading = true;
      const company = await this.$store.dispatch(
        "directorio/GetCompany",
        companyId
      );
      if (company) {
        this.company = company;
        // this.company.startDate = this.company.startDate;
        // this.company.endDate = this.company.endDate;
        this.$refs.br.SetBlocks(this.company.blocks.blocks);
      }
      this.loading = false;
    },

    OpenSocialLink,
    getUrl,
    show(item) {
      this.selectedImage = item;
      this.showImage = true;
    },
  },
  watch: {
    $route: function () {
      this.Load();
    },
  },
});
